import { PaddedContainer } from '@shared/components/atoms/PaddedContainer/PaddedContainer';
import { Card } from '@shared/components/molecules/Card/Card';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import ChallengeInfoContent from './ChallengeInfoContent';
import { useApiState } from '@shared/hooks/useApiState';
import { useNavigate, useParams } from 'react-router-dom';
import { ChallengesResidentAppGetByIdQuery } from '@shared/api/queries/ResidentApp/Challenges/ChallengesResidentAppGetByIdQuery';
import { Loading } from '@shared/components/atoms/Loading/Loading';
import PrizeInfoContent from './PrizeInfoContent';
import ImageInfoContent from './ImageInfoContent';
import { BlobStorageContainerType } from '@shared/api/enums/BlobStorageContainerType/BlobStorageContainerType';
import { useApi } from '@shared/hooks/useApi';
import { useEffect, useState } from 'react';
import { BlobResidentAppGetUriQuery } from '@shared/api/queries/ResidentApp/Blob/BlobResidentAppGetUriQuery';
import { useModal } from '@shared/hooks/useModal';
import { WarningDialog } from '@shared/components/molecules/WarningDialog/WarningDialog';
import { getDisplayChallengeStatus, getHasNoChallengesFeature } from '@dashboard/components/molecules/ResidentApp/ResidentAppUtils';
import { ChallengeStatus } from '@shared/api/models/ResidentApp/enums/ChallengeStatus';
import { Button } from '@shared/components/atoms/Button/Button';
import { ChallengesResidentAppCancelCommand } from '@shared/api/queries/ResidentApp/Challenges/ChallengesResidentAppCancelCommand';
import TitleWithButton from '@dashboard/components/molecules/ResidentApp/Common/TitleWIthButton';
import ChallengeWinners from './ChallengeWinners';
import TermsAndConditions from './TermsAndConditions';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';

type DialogType = 'cancel' | 'edit-info' | 'edit-prizes' | 'edit-image' | undefined;

const ResidentApp_ViewChallenge = () => {
  const { t } = useTranslation('molecules');
  const theme = useTheme();
  const { siteOperator } = useSiteContext();
  const { challengeId } = useParams<{ challengeId: string }>();
  const { execute, loading } = useApi(); 
  const [blobUri, setBlobUri] = useState<string | undefined>(undefined);
  const navigate = useNavigate();
  const { isOpen: dialogIsOpen, toggle: toggleDialog, ref: dialogRef } = useModal({});
  const [dialogType, setDialogType] = useState<DialogType>(undefined);

  const { data, loading: isChallengeLoading, execute: refresh } = useApiState({
    query: challengeId ? new ChallengesResidentAppGetByIdQuery(parseInt(challengeId)) : undefined,
    errorMessage: t('ResidentApp.ChallengeLoadFailed')
  }, []);

  const handleToggleDialog = (type: DialogType) => {
    setDialogType(type);
    toggleDialog();
  };

  const handleCancelChallenge = async () => {
    if(!data) {
      return;
    }

    await execute({
      query: new ChallengesResidentAppCancelCommand(data.id),
      successMessage: t('ChangesSaveSuccess', { ns: 'status' }),
      errorMessage: t('ChangesSaveError', { ns: 'status' }),
      pendingMessage: t('ChangesSavePending', { ns: 'status' }),
    }); 
    
    navigate('../../resident-app');
  };

  const handleOnConfirm = () => {
    switch(dialogType) {
      case 'cancel':
        handleCancelChallenge();
        break;
      case 'edit-info':
        navigate('./edit-info', { state: data });
        break;
      case 'edit-prizes':
        navigate('./edit-prizes', { state: data });
        break;
      case 'edit-image':
        navigate('./edit-image', {state: {...data, imageUrl: blobUri}});
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if(data?.imageId) {
      const fetchImage = async () => { 
        const response = await execute({
          query: new BlobResidentAppGetUriQuery(data.imageId ?? '', BlobStorageContainerType.Tenant),
          errorMessage: t('ResidentApp.ImageLoadFailed')
        });
        setBlobUri(response?.blobUri);
      }
      
      fetchImage();
    }
  }, [data, execute, t]);

  if (siteOperator && getHasNoChallengesFeature(siteOperator)) {
    return null;
  }
  
  return (
    <>
      <PaddedContainer>
        <TitleWithButton
          title={t('ResidentApp.ViewChallenge')}
          backButtonUrl="../../resident-app"
          button={
            <Button
              label={t('ResidentApp.CancelChallenge')}
              secondary
              color={theme.palette.systemMessage.error}
              onClick={() => handleToggleDialog('cancel')}
              disabled={data?.status !== ChallengeStatus.Open || loading || isChallengeLoading}
            />
          }
          style={{ marginBottom: '30px' }}
        />
        {data?.status === ChallengeStatus.Closed && data?.prizes && challengeId && (
          <ChallengeWinners
            prizes={data.prizes}
            isWinnersConfirmed={data.winnersConfirmed}
            onRefresh={refresh}
            challengeId={parseInt(challengeId)}
          />
        )}
        <ViewChallengeContainer noPadding>
          {isChallengeLoading ? (
            <Loading message={t('ResidentApp.LoadingChallenge')} />
          ) : (
            <Content>
              <ChallengeInfoContent
                challenge={data}
                onEdit={() => handleToggleDialog('edit-info')}
                isEditDisabled={data?.status !== ChallengeStatus.Open}
              />
              <PrizeInfoContent
                prizes={data?.prizes}
                onEdit={() => handleToggleDialog('edit-prizes')}
                isEditDisabled={data?.status !== ChallengeStatus.Open}
              />
              <ImageInfoContent
                imageUrl={blobUri}
                shortTitle={data?.shortTitle}
                onEdit={() => handleToggleDialog('edit-image')}
                isEditDisabled={data?.status !== ChallengeStatus.Open}
              />
              {data && (
                <TermsAndConditions
                  title={data.title ?? ''}
                  prizes={data.prizes}
                  openingDate={data.startDate}
                  closingDate={data.endDate}
                  targetValue={data.targetValue}
                />
              )}
            </Content>
          )}
        </ViewChallengeContainer>
      </PaddedContainer>
      <WarningDialog
        modalRef={dialogRef}
        isOpen={dialogIsOpen}
        sectionOne={t(
          `ResidentApp.${
            dialogType === 'cancel' ? 'CancelChallengeWarningTitle' : 'EditChallengeWarningTitle'
          }`,
          { status: t(data ? getDisplayChallengeStatus(data) : 'ResidentApp.Open').toLowerCase() }
        )}
        sectionTwo={t('ResidentApp.CancelChallengeWarningMessage')}
        confirmButton={
          dialogType === 'cancel' ? t('ResidentApp.CancelChallenge') : t('Edit', { ns: 'common' })
        }
        onCancel={toggleDialog}
        onConfirm={handleOnConfirm}
        cancelButtonType="tertiary"
        confirmButtonColor={dialogType !== 'cancel' ? theme.palette.primary : undefined}
      />
    </>
  );
}

export default ResidentApp_ViewChallenge;

const ViewChallengeContainer = styled(Card)`
  padding: 25px 20px;
  margin-top: 30px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;