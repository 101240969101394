import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@shared/components/atoms/Button/Button';
import LoadingWidget from '@shared/components/atoms/LoadingWidget/LoadingWidget';
import NoData from '@shared/components/atoms/NoData/NoData';
import { Card } from '@shared/components/molecules/Card/Card';
import { useSize } from '@shared/hooks/useSize';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { Paging } from './Paging';
import { ITable, ITableColumn, TableSortOrder } from './Table.types';
import TableHeader from './TableHeader';
import TableRecord from './TableRecord';

export const Table = <TRecord extends object>({ records, recordKey, columns, loading, defaultSortColumn, defaultSortOrder, disableSorting, minHeight, disablePaging, showOverflow, highlightSortedColumn, wrapBreakpoint, hideBreakpoint, emptyMessage, selectable, height, fullHeightSubtractor, bulkAction, cardEffect, card, highlightString, compact, fitContent, filters, onRowClick, onSelect, onRecordCountChange, onPageSizeChange, onPageChange, getPagedRecords, checkedRecords, removeDefaultStyling, resetTable, pageSizeOptions, isPagedTo, canSelectRecord, recordCount, recordBorder }: ITable<TRecord>) => {
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(pageSizeOptions?.[0]?.value ?? 10);
  const [filteredRecords, setFilteredRecords] = useState<TRecord[]>([]);
  const [pagedRecords, setPagedRecords] = useState<TRecord[]>([]);
  const [selectedRecords, setSelectedRecords] = useState<TRecord[]>([]);
  const [visibleColumns, setVisibleColumns] = useState<ITableColumn<TRecord>[]>([]);
  const [sortColumn, setSortColumn] = useState<string>(defaultSortColumn ?? '');
  const [sortOrder, setSortOrder] = useState<TableSortOrder>(defaultSortOrder ?? TableSortOrder.ASC);
  const [wrapColumns, setWrapColumns] = useState(false);
  const [hideColumns, setHideColumns] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { domRect: tableDomRect } = useSize(ref);

  /**
   * Changes when the Pagination changes 
   */
  useEffect(() => {
    onPageSizeChange && onPageSizeChange(pageSize)
  }, [pageSize, onPageSizeChange]);

  /**
   * Changes when the Pagination changes 
   */
  const updatePagedRecords = useCallback((updatedRecords: TRecord[]) => {
    if (getPagedRecords == undefined) {
      setPagedRecords(updatedRecords);
      return;
    }

    const fetchData = async () => {
      const newRecords = await getPagedRecords(page, pageSize, updatedRecords);
      setPagedRecords(newRecords);
    };

    fetchData();
  }, [getPagedRecords, page, pageSize]);


  /**
   * Changes when the page changes 
   */
  useEffect(() => {
    onPageChange && onPageChange(page)
  }, [page, onPageChange]);

  /**
   * Observe the width of the table and set wrapping/hiding of columns respectively
   */
  useEffect(() => {
    if (tableDomRect) {
      // div width + (2 * padding which is DOMRect.x)
      const tableWidth = tableDomRect.width + (2 * tableDomRect.x);
      setWrapColumns(tableWidth < (wrapBreakpoint ?? 600));
      setHideColumns(tableWidth < (hideBreakpoint ?? 600));
    }
  }, [tableDomRect, wrapBreakpoint, hideBreakpoint]);

  /**
   * Pass selected records to parent component when selection changes.
   */
  useEffect(() => {
    onSelect && onSelect(selectedRecords);
  }, [selectedRecords, onSelect]);

  /**
   * Resets pagination when pageSizeOptions prop is true
   */
  useEffect(() => {
    if (resetTable) {
      setPage(0)
      setPageSize(isPagedTo ?? 10)
    }
  }, [resetTable, isPagedTo]);

  /**
   * Set visible columns
   */
  useEffect(() => {
    const visible = columns.filter(x => !x.hidden && (!hideColumns || !x.hideWithBreakpoint));
    setVisibleColumns(visible);
  }, [columns, hideColumns]);

  /**
   * Reset selected records when list of records changes
   */
  useEffect(() => {
    setSelectedRecords([]);
  }, [records]);

  /**
   * Update records when filters change
   */
  useEffect(() => {
    if (getPagedRecords) { // Bypass filtering if we have a callback
      return;
    }

    let filtered = [...records];

    filters?.forEach(filter => {
      filtered = filtered.filter(x => filter(x));
    });

    onRecordCountChange && onRecordCountChange(filtered.length);
    setFilteredRecords(filtered);
  }, [records, filters, onRecordCountChange, getPagedRecords]);

  /**
   * Sort records by the current selected sortColumn and sortOrder.
   */
  const getSortedRecords = useCallback(() => {
    return [...filteredRecords].sort((a, b) => {
      const column = columns.find((x) => x.key === sortColumn);

      if (!column) {
        return 0;
      }

      const valueA = column.sortFormat ? column.sortFormat(a) : a[sortColumn as keyof TRecord];
      const valueB = column.sortFormat ? column.sortFormat(b) : b[sortColumn as keyof TRecord];

      const sortOrderNatural = sortOrder === TableSortOrder.ASC ? 1 : -1;
      const sortOrderReverse = sortOrder === TableSortOrder.ASC ? -1 : 1;

      const hasValueA = valueA !== undefined && valueA !== null;
      const hasValueB = valueB !== undefined && valueB !== null;

      // When valueA is undefined or null and valueB is not undefined or null, put valueB above valueA
      if (!hasValueA && hasValueB) {
        return sortOrderReverse;
      }

      // When valueB is undefined or null and valueA is not undefined or null, put valueA above valueB
      if (hasValueA && !hasValueB) {
        return sortOrderNatural;
      }

      // If at least one of valueA or valueB is null, then both must be null, otherwise the previous statements would have been triggered
      if (!hasValueA || !hasValueB) {
        return 0;
      }

      if (valueA > valueB || valueB === undefined) {
        return sortOrderNatural;
      }

      if (valueA < valueB || valueA === undefined) {
        return sortOrderReverse;
      }

      return 0;
    });
  }, [filteredRecords, sortColumn, sortOrder, columns]);

  /**
   * Update records when page or pageSize changes,
   * will also update when filteredRecords changes as a result of getSortedRecords function changing
   */
  useEffect(() => {
    if (getPagedRecords) { // Bypass sorting if we have a callback
      updatePagedRecords(filteredRecords);
      return;
    }

    const sorted = getSortedRecords();
    const startIndex = page * pageSize;
    const paged = sorted.slice(startIndex, startIndex + pageSize);
    updatePagedRecords(disablePaging ? sorted : paged);
  }, [disablePaging, filteredRecords, getPagedRecords, page, pageSize, getSortedRecords, updatePagedRecords]);

  /**
   * Returns true when some or all records on the current page are selected.
   */
  const someRecordsOnCurrentPageAreSelected = useCallback(() => {
    return pagedRecords.some((x: TRecord) => selectedRecords.some((a: TRecord) => a[recordKey as keyof TRecord] === x[recordKey as keyof TRecord]));
  }, [pagedRecords, selectedRecords, recordKey]);

  /**
   * Returns true when all records on the current page are selected.
   */
  const allRecordsOnCurrentPageAreSelected = useCallback(() => {
    const selectableRecords = canSelectRecord ? pagedRecords.filter(x => canSelectRecord(x)) : pagedRecords;
    return selectableRecords.every((x: TRecord) => selectedRecords.some((a: TRecord) => a[recordKey as keyof TRecord] === x[recordKey as keyof TRecord]));
  }, [pagedRecords, selectedRecords, recordKey, canSelectRecord]);

  /**
   * Select all records across pages which are currently filtered
   */
  const selectAllRecords = useCallback(() => {
    const selectableRecords = canSelectRecord ? filteredRecords.filter(x => canSelectRecord(x)) : filteredRecords;
    setSelectedRecords(selectableRecords);
  }, [filteredRecords, canSelectRecord]);

  /**
   * Handle mulit-select/deselect in heading column.
   * Deselect all records on the current page when some or all records on the page were previously selected,
   * select all records on the current page when none were previously selected.
   */
  const handleMultiSelect = useCallback(() => {
    if (someRecordsOnCurrentPageAreSelected()) {
      setSelectedRecords(selected => selected.filter(x => !pagedRecords.some(a => a[recordKey as keyof TRecord] === x[recordKey as keyof TRecord])));
    } else {
      const selectablePagedRecords = canSelectRecord ? pagedRecords.filter(x => canSelectRecord(x)) : pagedRecords;
      setSelectedRecords([...selectedRecords, ...selectablePagedRecords]);
    }
  }, [pagedRecords, selectedRecords, recordKey, someRecordsOnCurrentPageAreSelected, canSelectRecord]);

  /**
   * Handle select of a single row/record.
   */
  const handleSelect = useCallback((record: TRecord, selected: boolean) => {
    if (!selected) {
      setSelectedRecords(selected => selected.filter(x => x[recordKey as keyof TRecord] !== record[recordKey as keyof TRecord]));
    } else {
      setSelectedRecords(selected => [...selected, record]);
    }
  }, [recordKey]);

  const TableElement = (
    <>
      <TableHeader
        refProp={ref}
        columns={visibleColumns}
        sortColumn={sortColumn}
        selectable={selectable}
        someSelected={someRecordsOnCurrentPageAreSelected()}
        allSelected={allRecordsOnCurrentPageAreSelected()}
        onMultiSelect={handleMultiSelect}
        wrapColumns={wrapColumns}
        disableSorting={disableSorting}
        sortOrder={sortOrder}
        defaultSortOrder={defaultSortOrder}
        updateSortColumn={setSortColumn}
        updateSortOrder={setSortOrder}
        compact={compact}
      />

      {loading &&
        <LoadingWidget
          label={t('Loading', { ns: 'status' })}
          styles={{ margin: '60px 0' }}
        />
      }

      {!loading && pagedRecords.length === 0 && (
        <NoData
          label={emptyMessage ?? t('NoData', { ns: 'status' })}
          styles={{ margin: '60px 0' }}
        />
      )}

      {!loading && pagedRecords.length > 0 &&
        <ScrollContainer
          height={height}
          minHeight={minHeight}
          fullHeightSubtractor={fullHeightSubtractor}
          shrink={selectedRecords.length > 0 && !!bulkAction}
          disablePaging={disablePaging}
          showOverflow={showOverflow}
        >
          {pagedRecords.map((record) => {
            return (
              <TableRecord<TRecord>
                key={uuidv4()}
                record={record}
                onSelect={handleSelect}
                selected={selectedRecords.includes(record)}
                tableIsSelectable={selectable}
                recordIsSelectable={selectable && (canSelectRecord ? canSelectRecord(record) : true)}
                columns={visibleColumns}
                sortColumn={sortColumn}
                highlightSortedColumn={highlightSortedColumn}
                wrapColumns={wrapColumns}
                onRowClick={onRowClick}
                highlightString={highlightString}
                compact={compact}
                fitContent={fitContent}
                isChecked={checkedRecords?.includes(record)}
                removeDefaultStyling={removeDefaultStyling}
                recordBorder={recordBorder}
              />
            );
          })}
        </ScrollContainer>
      }
    </>
  );

  return (
    <Flex>
      {bulkAction &&
        <BulkActionWrapper show={selectedRecords.length > 0}>
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <Button
              tertiary
              circle
              label={<CloseBulkActionButton icon={faXmark} />}
              onClick={() => setSelectedRecords([])}
              style={{ padding: '5px' }}
            />

            <span>{selectedRecords.length} selected</span>

            {selectedRecords.length !== records.length &&
              <Button
                tertiary
                label='Select all'
                onClick={selectAllRecords}
                style={{ marginLeft: '-5px' }}
              />
            }
          </div>

          <div style={{ marginLeft: 'auto' }} />

          {bulkAction}
        </BulkActionWrapper>
      }

      <TableWrapper moveDown={selectedRecords.length > 0 && !!bulkAction}>
        {(cardEffect || card) &&
          <Card
            noMargin
            noPadding
            {...card}
          >
            {TableElement}
          </Card>
        }

        {!(cardEffect || card) &&
          <>
            {TableElement}
          </>
        }
      </TableWrapper>

      {!disablePaging && (
        <div style={{ display: 'flex', justifyContent: 'end', marginTop: compact ? '10px' : '20px' }}>
          <Paging
            numItems={recordCount || filteredRecords.length}
            onPageChange={setPage}
            onPageSizeChange={setPageSize}
            compact={wrapColumns || hideColumns}
            small={compact}
            pageSizeOptions={pageSizeOptions}
          />
        </div>
      )}
    </Flex>
  );
};

const Flex = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const TableWrapper = styled.div<{ moveDown: boolean }>`
  margin-top: ${p => p.moveDown ? '68px' : 0};;
  transition: all 150ms ease;
`;

const ScrollContainer = styled.div<{ height?: number, minHeight?: string, shrink: boolean, fullHeightSubtractor?: number, disablePaging?: boolean, showOverflow?: boolean }>`
  min-height: ${p => p.minHeight ?? '300px'};
  overflow: ${p => p.showOverflow ? 'unset' : 'hidden auto'};
  
  height: ${p => `calc(100% - ${p.disablePaging ? '0px' : '55px'} - ${p.shrink ? '68px' : '0px'})`};

  ${p => p.height && css`
    height: ${`calc(${p.height}px - ${p.shrink ? '68px' : '0px'})`};
    min-height: unset;
  `}

  ${p => p.fullHeightSubtractor && css`
    // -55px space for Paging, -68px space for bulk action bar when shown
    height: ${`calc(100vh - ${p.fullHeightSubtractor}px - ${p.disablePaging ? '0px' : '55px'} - ${p.shrink ? '68px' : '0px'})`};
  `}
`;

const BulkActionWrapper = styled.div<{ show: boolean }>`
  opacity: ${p => p.show ? 1 : 0};
  visibility: ${p => p.show ? 'visible' : 'hidden'};

  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 60px;
  padding: 0px 20px;

  display: flex;
  align-items: center;
  gap: 20px;

  font-weight: 500;
  color: ${p => p.theme.palette.text.fair};
  background-color: ${p => p.theme.palette.backgrounds.surface};
  border-radius: 5px;
  box-shadow: 0 0 8px 0px ${p => p.theme.palette.shadows.medium};
`;

const CloseBulkActionButton = styled(FontAwesomeIcon)`
  width: 16px;
  height: 16px;
  color: ${p => p.theme.palette.text.fair};
`;