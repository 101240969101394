import { OperatorChallengeDto } from '@shared/api/models/ResidentApp/OperatorChallengeDto';
import { ChallengeStatus } from '@shared/api/models/ResidentApp/enums/ChallengeStatus';
import { isFuture } from 'date-fns';
import { Dayjs } from 'dayjs';
import { ResidentCsvObject } from './Users/ResidentApp_ManageUsers';
import { t } from 'i18next';
import { SiteOperatorDto } from '@shared/api/models/ResidentApp/SiteOperatorDto';
import { CustomResidentAppFeature } from '@shared/api/enums/ResidentAppFeatureType/ResidentAppFeatureType';

/**
 * Get display Challenge Status, returns the translation key for status string
 */
export const getDisplayChallengeStatus = (challenge: OperatorChallengeDto) => {
  if (isFuture(new Date(challenge.startDate)) && challenge.status === ChallengeStatus.Open) {
    return 'ResidentApp.Upcoming';
  }

  if (!challenge.winnersConfirmed && challenge.status === ChallengeStatus.Closed){
    return 'ResidentApp.PendingWinners';
  }

  return `ResidentApp.${challenge.status}`;
};

/**
 * Checks if end date comes after start date
 */
export const isEndDateAfterStartDate = (startDate: Dayjs, endDate: Dayjs) => {
  return startDate <= endDate;
};

/**
 * Validates CSV file to make sure it's in the right format as ResidentCsvObject
 */
export const isValidResidentCsvObject = (csvObject: ResidentCsvObject) => {
  // required keys
  const keys: { [key: string]: string } = {
    key: 'string',
    buildingName: 'string',
    spaceName: 'string',
    email: 'string',
    firstName: 'string',
    lastName: 'string',
    startTenancy: 'string',
    endTenancy: 'string'
  };

  for (const key in keys){
    if (!(key in csvObject) || typeof csvObject[key as keyof ResidentCsvObject] !== keys[key]) {
      return false;
    }
  }

  return true;
};

/**
 * Maps arrays of string arrays to an Object
 */
export const mapStringArrayToResidentCsvObject = (data: string[][]) => {
  const headers = data[0].map(x => x.charAt(0).toLowerCase() + x.slice(1).replace(/\s/g, ''));
  headers.push('key');

  return data.slice(1).map((row, rowNumber) => {
    const obj: ResidentCsvObject = {} as ResidentCsvObject;
    headers.forEach((header, index) => {
      obj[header as keyof ResidentCsvObject] = header === 'key' ? `${rowNumber + 1}` : row[index];
    });

    return obj;
  }).filter(isValidResidentCsvObject);
};

/**
 * Gets the ordinal number suffix for a number
 */
export const getOrdinalNumber = (num: number): string => {
  if (num % 100 >= 11 && num % 100 <= 13) {
    return num + t('OrdinalSuffix.default', {ns: 'common'});
  }
  
  const lastDigit = num % 10;
  const suffix =
    {
      1: t('OrdinalSuffix.1', { ns: 'common' }),
      2: t('OrdinalSuffix.2', { ns: 'common' }),
      3: t('OrdinalSuffix.3', { ns: 'common' }),
    }[lastDigit] || t('OrdinalSuffix.default', { ns: 'common' });
  
  return num + suffix;
}

/**
 * Gets the site metric type for NoChallenges feature
 */
export const getHasNoChallengesFeature = (siteOperator: SiteOperatorDto) => {
  return siteOperator?.siteMetricTypes?.find(
    (siteMetricType) => siteMetricType.metricType === CustomResidentAppFeature.NoChallenges
  );
}