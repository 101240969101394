import { PaddedContainer } from '@shared/components/atoms/PaddedContainer/PaddedContainer';
import TitleWithButton from '../Common/TitleWIthButton';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { Card } from '@shared/components/molecules/Card/Card';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { OperatorChallengeDto } from '@shared/api/models/ResidentApp/OperatorChallengeDto';
import { useApi } from '@shared/hooks/useApi';
import { ChallengesResidentAppGetByIdQuery } from '@shared/api/queries/ResidentApp/Challenges/ChallengesResidentAppGetByIdQuery';
import { BlobResidentAppGetUriQuery } from '@shared/api/queries/ResidentApp/Blob/BlobResidentAppGetUriQuery';
import { BlobStorageContainerType } from '@shared/api/enums/BlobStorageContainerType/BlobStorageContainerType';
import { FormProvider, useForm } from 'react-hook-form';
import { CreateChallengeForm } from './ResidentApp_CreateChallenge';
import ImageInfoContent, { ImageType } from './ImageInfoContent';
import { Button } from '@shared/components/atoms/Button/Button';
import { useFileHandler } from '@shared/hooks/useFileHandler';
import { ChallengesResidentAppUpdateInfoCommand } from '@shared/api/queries/ResidentApp/Challenges/ChallengesResidentAppUpdateInfoCommand';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { ChallengesResidentAppUploadImageCommand } from '@shared/api/queries/ResidentApp/Challenges/ChallengesResidentAppUploadImageCommand';
import { getHasNoChallengesFeature } from '@dashboard/components/molecules/ResidentApp/ResidentAppUtils';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';

type OperatorChallengeWithImageUrl = OperatorChallengeDto & {
  imageUrl?: string;
};

const ResidentApp_EditImage = () => {
  const { t } = useTranslation('molecules');
  const theme = useTheme();
  const { siteOperator } = useSiteContext();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { challengeId } = useParams<{ challengeId: string }>(); 
  const {execute, loading, error} = useApi();
  const {file, handleFileChange, handleFileDelete} = useFileHandler();
  const [challengeData, setChallengeData] = useState<OperatorChallengeWithImageUrl | undefined>(state);
  const [customImageUrl, setCustomImageUrl] = useState<string | undefined>(challengeData?.imageUrl)

  const methods = useForm<CreateChallengeForm>({
    defaultValues: {   
      shortTitle: challengeData?.shortTitle,
      imageType: challengeData?.imageUrl ? ImageType.UploadImage : ImageType.UseTemplate
    }
  });

  const handleImageChange = (files: File[]) => {
    const url = URL.createObjectURL(files[0]);
    setCustomImageUrl(url);
    handleFileChange(files[0]);
  };

  const handleImageDelete = () => {
    setCustomImageUrl(undefined);
    handleFileDelete();
  };

  const handleUpdateImage = async (data: CreateChallengeForm) => {
    
    if(!challengeData) {
      return;
    }

    if (data.imageType === ImageType.UseTemplate) {
      await execute({
        query: new ChallengesResidentAppUpdateInfoCommand(
          challengeData.id,
          challengeData.title ?? '',
          data.shortTitle ?? t('ResidentApp.NewChallenge'),
          challengeData.description ?? '',
          MetricType.Temperature,
          challengeData.targetValue,
          challengeData.startDate,
          challengeData.endDate,
          challengeData.siteId,
          ''
        ),
        successMessage: t('ChangesSaveSuccess', { ns: 'status' }),
        errorMessage: t('ChangesSaveError', { ns: 'status' }),
        pendingMessage: t('ChangesSavePending', { ns: 'status' }),
      });
    }

    if(data.imageType === ImageType.UploadImage && file)
    {
      await execute({
        query: new ChallengesResidentAppUploadImageCommand(challengeData.id, file),
        successMessage: t('UploadChallengeImageSuccess', { ns: 'status' }),
        errorMessage: t('UploadChallengeImageError', { ns: 'status' }),
        pendingMessage: t('UploadChallengeImagePending', { ns: 'status' }),
      })
    }

    if(!error) {
      navigate('../');
    }
  };
  
  useEffect(() => {
    const getChallenge = async () => {
      if (!challengeId) {
        return;
      }

      const response = await execute({
        query: new ChallengesResidentAppGetByIdQuery(parseInt(challengeId)),
        errorMessage: t('ResidentApp.ChallengeLoadFailed')
      });

      setChallengeData(response);
      methods.setValue('shortTitle', response?.shortTitle);

      if (response?.imageId) {
        const imageUrl = await execute({
          query: new BlobResidentAppGetUriQuery(response.imageId, BlobStorageContainerType.Tenant),
          errorMessage: t('ResidentApp.ImageLoadFailed')
        });

        setChallengeData({...response, imageUrl: imageUrl?.blobUri});
        methods.setValue('imageType', ImageType.UploadImage);
      }
    };

    if (!state) {
      getChallenge();
    }
  }, [challengeId, execute, methods, state, t]);
  
  if (siteOperator && getHasNoChallengesFeature(siteOperator)) {
    return null;
  }

  return (
    <PaddedContainer>
      <TitleWithButton
        title={t('ResidentApp.EditImage')}
        backButtonUrl='../'
        style={{ padding: '0 0 30px 0' }}
      />
      <EditImageContainer noPadding>
        <FormProvider {...methods}>
          <ImageInfoContent
            methods={methods}
            imageUrl={customImageUrl}
            shortTitle={challengeData?.shortTitle}
            onImageChange={handleImageChange}
            onImageDelete={handleImageDelete}
          />
          <Buttons>
            <Button
              label={t('Cancel', { ns: 'common' })}
              onClick={() => navigate('../')}
              tertiary
              color={theme.palette.systemMessage.error}
              disabled={loading}
            />
            <Button
              label={t('Update', { ns: 'common' })}
              onClick={methods.handleSubmit((data) => handleUpdateImage(data))}
              disabled={loading}
            />
          </Buttons>
        </FormProvider>
      </EditImageContainer>
    </PaddedContainer>
  );
}

export default ResidentApp_EditImage;

const EditImageContainer = styled(Card)`
  padding: 25px 20px;
  margin-top: 30px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;